import React, { useEffect, useState } from 'react';
import SalesforceAuth from './components/SalesforceAuth';
import FileUploader from './components/FileUploader';
import { useSessionCheck } from './hooks/useSessionCheck';
import { CloudLightning, Loader2 } from 'lucide-react';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkInitialSession = async () => {
            try {

                // for local
                /* const response = await fetch('http://localhost:3001/api/session-check', {
                    credentials: 'include'
                }); */

                // for production   
                const response = await fetch('https://opportunity-product.bestinbrands.co/api/session-check', {
                    credentials: 'include'
                });
                const data = await response.json();
                
                if (data.authenticated) {
                    setIsAuthenticated(true);
                }
            } catch (error) {
                console.error('Session check failed:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const queryParams = new URLSearchParams(window.location.search);
        const loginStatus = queryParams.get('login');
        
        if (loginStatus === 'success') {
            console.log('Login successful, setting authenticated state');
            setIsAuthenticated(true);
            window.history.replaceState({}, document.title, window.location.pathname);
            setIsLoading(false);
        } else if (loginStatus === 'expired') {
            console.log('Session expired, setting unauthenticated state');
            setIsAuthenticated(false);
            window.history.replaceState({}, document.title, window.location.pathname);
            setIsLoading(false);
        } else {
            // If no login status in URL, check session
            checkInitialSession();
        }
    }, []);

    useSessionCheck(isAuthenticated, setIsAuthenticated);

    if (isLoading) {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-blue-50 to-white">
                <div className="text-center">
                    <Loader2 className="w-8 h-8 text-blue-500 animate-spin mx-auto" />
                    <p className="mt-4 text-gray-600 font-medium">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
            {/* Header */}
            <header className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                            <div className="bg-gradient-to-r from-blue-600 to-blue-700 p-2 rounded-lg">
                                <CloudLightning className="w-6 h-6 text-white" />
                            </div>
                            <h1 className="text-xl font-bold text-gray-900">
                                Salesforce Integration App
                            </h1>
                        </div>
                        {isAuthenticated && (
                            <button
                                onClick={() => {
                                    setIsAuthenticated(false);
                                    window.location.href = '/';
                                }}
                                className="text-sm text-gray-500 hover:text-gray-700 font-medium"
                            >
                                Logout
                            </button>
                        )}
                    </div>
                </div>
            </header>

            {/* Main Content */}
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="max-w-xl mx-auto">
                    {!isAuthenticated ? (
                        <SalesforceAuth />
                    ) : (
                        <div className="space-y-6">
                            <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
                                <h2 className="text-2xl font-bold text-gray-900 mb-2">
                                    Welcome! 👋
                                </h2>
                                <p className="text-gray-600">
                                    You're now connected to Salesforce. Use the uploader below to process your CSV files.
                                </p>
                            </div>
                            
                            <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
                                <FileUploader />
                            </div>
                        </div>
                    )}
                </div>
            </main>

            {/* Footer */}
            <footer className="mt-auto py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <p className="text-center text-sm text-gray-500">
                        &copy; {new Date().getFullYear()} Best In Brands. All rights reserved.
                    </p>
                </div>
            </footer>
        </div>
    );
}

export default App;