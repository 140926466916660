import React, { useState, useRef } from 'react';
import { Upload, X, FileText, Check, AlertCircle, Download } from 'lucide-react';

const FileUploader = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [status, setStatus] = useState(null); // 'success' | 'error' | null
  const [statusMessage, setStatusMessage] = useState('');
  const fileInputRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.name.endsWith('.csv')) {
      setFile(droppedFile);
      setStatus(null);
    } else {
      setStatus('error');
      setStatusMessage('Please upload a CSV file');
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setStatus(null);
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      setStatus('error');
      setStatusMessage('Please select a file to upload.');
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {

      // for local
      /* const response = await fetch('http://localhost:3001/upload', {
        method: 'POST',
        body: formData,
        credentials: 'include'
      }); */

      // for production
      const response = await fetch('https://opportunity-product.bestinbrands.co/upload', {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      if (!response.ok) {
        if (response.status === 401) {
          setStatus('error');
          setStatusMessage('Session expired. Please log in again.');
          setTimeout(() => {
            window.location.href = '/';
          }, 2000);
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Handle CSV file download
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `results_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);

      setStatus('success');
      setStatusMessage('File processed successfully. Results downloaded.');
    } catch (error) {
      console.error('Upload error:', error);
      setStatus('error');
      setStatusMessage('Error processing file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const resetUploader = () => {
    setFile(null);
    setStatus(null);
    setStatusMessage('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="space-y-6">
      {/* Main upload area */}
      <div 
        className={`relative rounded-lg ${
          dragActive ? 'border-2 border-dashed border-blue-500 bg-blue-50' :
          file ? 'border-2 border-dashed border-green-500 bg-green-50' :
          'border-2 border-dashed border-gray-300 hover:border-gray-400 bg-white'
        } transition-colors duration-200`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          onChange={handleFileChange}
          accept=".csv"
        />
        
        <div className="p-8 text-center">
          {!file ? (
            // Upload prompt state
            <div className="space-y-4">
              <div className="mx-auto w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center">
                <Upload className="w-6 h-6 text-gray-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  Upload your CSV file
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Drag and drop your file here, or{' '}
                  <button 
                    onClick={() => fileInputRef.current?.click()}
                    className="text-blue-500 hover:text-blue-600 font-medium"
                    type="button"
                  >
                    browse
                  </button>
                </p>
                <p className="mt-2 text-xs text-gray-500">
                  Supported format: CSV
                </p>
              </div>
            </div>
          ) : (
            // File selected state
            <div className="space-y-4">
              <div className="mx-auto w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
                <FileText className="w-6 h-6 text-green-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 flex items-center justify-center gap-2">
                  <span>{file.name}</span>
                  <button
                    onClick={resetUploader}
                    className="p-1 hover:bg-gray-200 rounded-full transition-colors duration-200"
                  >
                    <X className="w-4 h-4 text-gray-500" />
                  </button>
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {Math.round(file.size / 1024)} KB
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Status message */}
      {status && (
        <div className={`rounded-lg p-4 flex items-center gap-3 ${
          status === 'error' ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'
        }`}>
          {status === 'error' ? (
            <AlertCircle className="w-5 h-5 flex-shrink-0" />
          ) : (
            <Check className="w-5 h-5 flex-shrink-0" />
          )}
          <p className="text-sm font-medium">{statusMessage}</p>
        </div>
      )}

      {/* Upload button */}
      <button
        onClick={handleFileUpload}
        disabled={!file || loading}
        className={`w-full py-3 px-4 rounded-lg flex items-center justify-center gap-2 font-medium transition-colors duration-200 ${
          !file || loading
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        {loading ? (
          <>
            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
            <span>Processing...</span>
          </>
        ) : file ? (
          <>
            <Upload className="w-5 h-5" />
            <span>Upload and Process CSV</span>
          </>
        ) : (
          <>
            <Upload className="w-5 h-5" />
            <span>Select a file first</span>
          </>
        )}
      </button>

      {/* Instructions */}
      <div className="rounded-lg border border-gray-200 p-4 bg-gray-50">
        <h4 className="font-medium text-gray-900 mb-2">Instructions</h4>
        <ul className="text-sm text-gray-600 space-y-1">
          <li>• Upload a CSV file with the required columns</li>
          <li>• File will be processed and matched with Salesforce records</li>
          <li>• Results will be automatically downloaded as a new CSV</li>
          <li>• Check the results file for success/error status of each record</li>
        </ul>
      </div>
    </div>
  );
};

export default FileUploader;