// src/hooks/useSessionCheck.js
import { useEffect } from 'react';

export const useSessionCheck = (isAuthenticated, setIsAuthenticated) => {
  useEffect(() => {
    if (!isAuthenticated) return;

    const checkSession = async () => {
      try {
        console.log('Checking session status...');
        // for production
        const response = await fetch('https://opportunity-product.bestinbrands.co/api/session-check', {
          credentials: 'include'
        });

        // for local
        /* const response = await fetch('http://localhost:3001/api/session-check', {
          credentials: 'include'
        }); */

        const data = await response.json();
        console.log('Session check response:', data);

        if (!response.ok || !data.authenticated) {
          console.log('Session is invalid, logging out');
          setIsAuthenticated(false);
          window.location.href = '/?login=expired';
        }
      } catch (error) {
        console.error('Session check failed:', error);
        // Don't automatically redirect on network errors
        if (error.message !== 'Failed to fetch') {
          setIsAuthenticated(false);
          window.location.href = '/?login=expired';
        }
      }
    };

    // Check every 5 minutes instead of immediately
    const interval = setInterval(checkSession, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [isAuthenticated, setIsAuthenticated]);
};