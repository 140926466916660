import React from 'react';
import { LogIn } from 'lucide-react';

const SalesforceAuth = () => {

  const handleLogin = () => {
    window.location.href = 'https://opportunity-product.bestinbrands.co/oauth2/auth';
  };

  // for local
  /* const handleLogin = () => {
    window.location.href = 'http://localhost:3001/oauth2/auth';
  }; */

  return (
    <div className="w-full max-w-xl mx-auto p-8">
      <div className="bg-white rounded-xl shadow-lg p-8">
        <div className="text-center space-y-8">
          {/* Logo and Title */}
          <div className="space-y-6">
            <div className="flex justify-center">
              <div className="bg-gradient-to-r from-blue-600 to-blue-700 p-4 rounded-xl shadow-md">
                <LogIn className="w-8 h-8 text-white" />
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-3">Connect to Salesforce</h2>
              <p className="text-lg text-gray-600">
                Log in with your Salesforce account to start uploading CSV files
              </p>
            </div>
          </div>

          {/* Login Button */}
          <button
            onClick={handleLogin}
            className="w-full bg-gradient-to-r from-blue-600 to-blue-700 text-white 
                     rounded-lg px-6 py-4 text-lg font-semibold shadow-md
                     hover:from-blue-700 hover:to-blue-800 
                     transform transition duration-200 hover:shadow-lg"
          >
            <div className="flex items-center justify-center space-x-3">
              <LogIn className="w-6 h-6" />
              <span>Log in with Salesforce</span>
            </div>
          </button>

          {/* Information Cards */}
          <div className="grid gap-6 mt-8">
            <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg border border-blue-200">
              <h3 className="font-semibold text-gray-900 text-lg mb-2">Secure Connection</h3>
              <p className="text-gray-700">
                Your connection is protected with industry-standard OAuth 2.0 security
              </p>
            </div>
            
            <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg border border-blue-200">
              <h3 className="font-semibold text-gray-900 text-lg mb-2">Data Privacy</h3>
              <p className="text-gray-700">
                We only request the minimum permissions needed to process your CSV files
              </p>
            </div>
          </div>

          {/* Error Alert */}
          {new URLSearchParams(window.location.search).get('login') === 'failed' && (
            <div className="mt-6 p-4 bg-red-50 text-red-700 rounded-lg border border-red-200">
              Unable to connect to Salesforce. Please try again or contact support if the issue persists.
            </div>
          )}

          {/* Footer */}
          <div className="pt-6 mt-8 border-t border-gray-200">
            <p className="text-sm text-gray-500">
              By connecting, you agree to our{' '}
              <a href="#" className="text-blue-600 hover:text-blue-700">Terms of Service</a>
              {' '}and{' '}
              <a href="#" className="text-blue-600 hover:text-blue-700">Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesforceAuth;